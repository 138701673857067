<template>
  <div>
    <section>
      <b-form id="citydetails-from" name="citydetails-from" v-if="showForm" @submit.prevent="onSubmitCity" autocomplete="off" novalidate>
        <b-form-row>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">City name</h4>
              <div class="floating-input-field">
                <b-form-input id="city-name" type="text" placeholder=" " v-model="cityDetails.cityName" required></b-form-input>
                <label for="city-name">City name</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.cityName && formSubmitted">City name required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="userRole == 'SysAdmin'">
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Tenant service id</h4>
              <div class="floating-select-field">
                <b-form-select
                  id="tenant-service-id"
                  class="form-control"
                  value-field="id"
                  text-field="name"
                  :options="tenantServiceIdList"
                  v-model="cityDetails.tenantServiceId"
                  @input="cityDetails.tenantServiceId = $event"
                  :class="{
                    'is-value-exist': cityDetails.tenantServiceId != null,
                    'is-invalid': !cityDetails.tenantServiceId && formSubmitted,
                  }"
                >
                </b-form-select>
                <label for="tenant-service-id">Tenant service id</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.tenantServiceId && formSubmitted">Tenant service id</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">App name</h4>
              <div class="floating-input-field">
                <b-form-input id="app-name" type="text" placeholder=" " v-model="cityDetails.appName" required></b-form-input>
                <label for="app-name">App name</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.appName && formSubmitted">App name required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Host name</h4>
              <div class="floating-input-field">
                <b-form-input id="host-name" type="text" placeholder=" " v-model="cityDetails.hostName" required></b-form-input>
                <label for="host-name">Host name</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.hostName && formSubmitted">Host name required.</b-form-invalid-feedback>
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.hostName && formSubmitted && !validateHostname(cityDetails.hostName)"
                  >Invalid host name.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Timezone</h4>
              <div class="floating-select-field">
                <b-form-select
                  id="timezone-dropdown"
                  class="form-control"
                  :options="timezoneList"
                  v-model="cityDetails.timeZone"
                  @input="cityDetails.timeZone = $event"
                  :class="{
                    'is-value-exist': cityDetails.timeZone != null,
                    'is-invalid': !cityDetails.timeZone && formSubmitted,
                  }"
                >
                </b-form-select>
                <label for="timezone-dropdown">Timezone</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.timeZone && formSubmitted">Timezone required</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Fav icon link</h4>
              <div class="floating-input-field">
                <b-form-input id="fav-icon-link" type="url" placeholder=" " v-model="cityDetails.favIconLnk" required></b-form-input>
                <label for="fav-icon-link">Fav icon link</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.favIconLnk && formSubmitted">Fav icon link required.</b-form-invalid-feedback>
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.favIconLnk && formSubmitted && !validateUrl(cityDetails.favIconLnk)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Webpage shortcut icon</h4>
              <div class="floating-input-field">
                <b-form-input id="webpage-shortcut-icon" type="url" placeholder=" " v-model="cityDetails.shortcutIconLnk"></b-form-input>
                <label for="webpage-shortcut-icon">Webpage shortcut icon</label>
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.shortcutIconLnk && formSubmitted && !validateUrl(cityDetails.shortcutIconLnk)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <h6 class="font-14">
              <InfoCircelYellow class="mr-1 mt-n1" />
              Logo images should be svg only with a maximum file size of 2 MB. View additional
              <a href="https://resourcecentre.esolutionsgroup.ca/en/citizen-portal/svg-guidelines-for-logos.aspx" target="_blank">svg guidelines.</a>
            </h6>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group class="file-upload-container">
              <h4 class="page-sub-header">Banner Logo: Light</h4>
              <div
                class="city-file-uploaded-area"
                :style="{ backgroundColor: cityDetails.bannerBackGrndColor }"
                v-if="cityDetails.logoBannerFile || cityDetails.logoBannerURI"
              >
                <img :src="getObjectURL(cityDetails.logoBannerFile, cityDetails.logoBannerURI)" alt="Logo: Image" />
              </div>
              <label for="banner-logo-light-file-upload" class="custom-file-button btn btn-primary">
                <span>{{ cityDetails.logoBannerFile || cityDetails.logoBannerURI ? 'Change logo' : 'Add logo' }}</span>
                <b-form-file
                  id="banner-logo-light-file-upload"
                  class="cutom-file-input"
                  accept="image/svg+xml,.svg"
                  @change="changeLogo($event, 'logoBannerFile', 'svg')"
                  plain
                ></b-form-file>
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.logoBannerFile && !cityDetails.logoBannerURI && formSubmitted"
                >Logo: Banner required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group class="file-upload-container">
              <h4 class="page-sub-header">Banner Logo: Dark</h4>
              <div class="city-file-uploaded-area" v-if="cityDetails.darkLogoBannerFile || cityDetails.darkLogoBannerURI">
                <img :src="getObjectURL(cityDetails.darkLogoBannerFile, cityDetails.darkLogoBannerURI)" alt="Logo: Image" />
              </div>
              <label for="banner-logo-dark-file-upload" class="custom-file-button btn btn-primary">
                <span>{{ cityDetails.darkLogoBannerFile || cityDetails.darkLogoBannerURI ? 'Change logo' : 'Add logo' }}</span>
                <b-form-file
                  id="banner-logo-dark-file-upload"
                  class="cutom-file-input"
                  accept="image/svg+xml,.svg"
                  @change="changeLogo($event, 'darkLogoBannerFile', 'svg')"
                  plain
                ></b-form-file>
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.darkLogoBannerFile && !cityDetails.darkLogoBannerURI && formSubmitted"
                >Logo: Banner required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group class="file-upload-container">
              <h4 class="page-sub-header">Footer Logo: Light</h4>
              <div
                class="city-file-uploaded-area"
                :style="{ backgroundColor: cityDetails.bannerBackGrndColor }"
                v-if="cityDetails.footerLogoFile || cityDetails.footerLogoURI"
              >
                <img :src="getObjectURL(cityDetails.footerLogoFile, cityDetails.footerLogoURI)" alt="Logo: Image" />
              </div>
              <label for="footer-logo-light-file-upload" class="custom-file-button btn btn-primary">
                <span>{{ cityDetails.footerLogoFile || cityDetails.footerLogoURI ? 'Change logo' : 'Add logo' }}</span>
                <b-form-file
                  id="footer-logo-light-file-upload"
                  class="cutom-file-input"
                  accept="image/svg+xml,.svg"
                  @change="changeLogo($event, 'footerLogoFile', 'svg')"
                  plain
                ></b-form-file>
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.footerLogoFile && !cityDetails.footerLogoURI && formSubmitted"
                >Logo: Image required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group class="file-upload-container">
              <h4 class="page-sub-header">Footer Logo: Dark</h4>
              <div class="city-file-uploaded-area" v-if="cityDetails.darkFooterLogoFile || cityDetails.darkFooterLogoURI">
                <img :src="getObjectURL(cityDetails.darkFooterLogoFile, cityDetails.darkFooterLogoURI)" alt="Logo: Image" />
              </div>
              <label for="footer-logo-dark-file-upload" class="custom-file-button btn btn-primary">
                <span>{{ cityDetails.darkFooterLogoFile || cityDetails.darkFooterLogoURI ? 'Change logo' : 'Add logo' }}</span>
                <b-form-file
                  id="footer-logo-dark-file-upload"
                  class="cutom-file-input"
                  accept="image/svg+xml,.svg"
                  @change="changeLogo($event, 'darkFooterLogoFile', 'svg')"
                  plain
                ></b-form-file>
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.darkFooterLogoFile && !cityDetails.darkFooterLogoURI && formSubmitted"
                >Logo: Image required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <h6 class="font-14">
              <InfoCircelYellow class="mr-1 mt-n1" />
              Following images can be jpeg, png or webp with a maximum file size of 2 MB.
            </h6>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Login: Background screen</h4>
              <h6 class="font-14 invisible">
                <InfoCircelYellow class="mr-1 mt-n1" />
                Recommended dimensions: 1920 x 1080
              </h6>
              <div class="city-file-uploaded-area" v-if="cityDetails.loginBackGrndScreenFile || cityDetails.loginBackGrndScreenURI">
                <img :src="getObjectURL(cityDetails.loginBackGrndScreenFile, cityDetails.loginBackGrndScreenURI)" alt="Logo: Image" />
              </div>
              <label for="login-background-screen-file-upload" class="custom-file-button btn btn-primary">
                <span>{{ cityDetails.loginBackGrndScreenFile || cityDetails.loginBackGrndScreenURI ? 'Change image' : 'Add image' }}</span>
                <b-form-file
                  id="login-background-screen-file-upload"
                  class="cutom-file-input"
                  :accept="getAcceptedFormat()"
                  @change="changeLogo($event, 'loginBackGrndScreenFile', 'others')"
                  plain
                ></b-form-file>
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.loginBackGrndScreenFile && !cityDetails.loginBackGrndScreenURI && formSubmitted"
                >Login: Background screen required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Background: Onboarding</h4>
              <h6 class="font-14">(width: 0 to 740px; height: 240px)</h6>
              <div
                class="city-file-uploaded-area"
                :style="{
                  backgroundColor: cityDetails.onBoardingBackGrndColor,
                }"
                v-if="cityDetails.backgroundOnBoardingFile || cityDetails.backgroundOnBoardingURI"
              >
                <img :src="getObjectURL(cityDetails.backgroundOnBoardingFile, cityDetails.backgroundOnBoardingURI)" alt="Logo: Image" />
              </div>
              <label for="background-onboarding-file-upload" class="custom-file-button btn btn-primary">
                <span>{{ cityDetails.backgroundOnBoardingFile || cityDetails.backgroundOnBoardingURI ? 'Change image' : 'Add image' }}</span>
                <b-form-file
                  id="background-onboarding-file-upload"
                  class="cutom-file-input"
                  :accept="getAcceptedFormat()"
                  @change="changeLogo($event, 'backgroundOnBoardingFile', 'others')"
                  plain
                ></b-form-file>
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.backgroundOnBoardingFile && !cityDetails.backgroundOnBoardingURI && formSubmitted"
                >Background: Onboarding required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="6" lg="6">
            <h4 class="page-sub-header">Header: Banner</h4>
            <div class="d-flex">
              <b-form-radio-group
                class="mt-n1"
                v-model="cityDetails.bannerOptionSelected"
                :options="bannerOptions"
                @change="cityDetails.bannerBackGrndColor = null"
                name="radio-bannerOption"
              ></b-form-radio-group>
              <h6 class="font-14 ml-n2">(width: 1800px; height: 140px)</h6>
            </div>
            <template v-if="cityDetails.bannerOptionSelected == 'color'">
              <b-form-row class="pt-3">
                <b-col cols="12" sm="6">
                  <h5 class="page-sub-header pt-3">Banner background color</h5>
                </b-col>
                <b-col cols="10" sm="6" md="4">
                  <b-form-group>
                    <div>
                      <b-form-input
                        id="Banner-color"
                        type="text"
                        placeholder="Banner color"
                        class="custom-color-input"
                        :class="{
                          valueExist: cityDetails.bannerBackGrndColor,
                        }"
                        v-model="cityDetails.bannerBackGrndColor"
                        @focus="showBannerBackGrndColorPicker = true"
                        @blur="showBannerBackGrndColorPicker = false"
                        @input="applyBannerBackGrndColorToPicker"
                        required
                      ></b-form-input>
                      <color-picker
                        v-if="showBannerBackGrndColorPicker"
                        theme="dark"
                        :color="cityDetails.bannerBackGrndColor"
                        :sucker-hide="true"
                        @input="changeBannerBackGrndColor"
                        @changeColor="changeBannerBackGrndColor"
                        class="color-picker"
                      />
                      <div class="preview-city-color-wraper" v-if="cityDetails.bannerBackGrndColor">
                        <div
                          class="preview-city-color"
                          :style="{
                            backgroundColor: cityDetails.bannerBackGrndColor,
                          }"
                        ></div>
                      </div>
                    </div>
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="cityDetails.bannerOptionSelected == 'color' && !cityDetails.bannerBackGrndColor && formSubmitted"
                      >Banner background color required.</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback :state="isValidBannerBackGrndColor">Invalid color <code></code></b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </template>
            <template v-else>
              <b-form-row>
                <b-col cols="12" sm="7" md="5" lg="3">
                  <b-form-group>
                    <div class="city-file-uploaded-area" v-if="cityDetails.bannerBackGrndImageFile || cityDetails.bannerBackGrndImageURI">
                      <img :src="getObjectURL(cityDetails.bannerBackGrndImageFile, cityDetails.bannerBackGrndImageURI)" alt="Logo: Image" />
                    </div>
                    <label for="banner-background-screen-file-upload" class="custom-file-button btn btn-primary">
                      <span>{{ cityDetails.bannerBackGrndImageFile || cityDetails.bannerBackGrndImageURI ? 'Change image' : 'Add image' }}</span>
                      <b-form-file
                        id="banner-background-screen-file-upload"
                        class="cutom-file-input"
                        :accept="getAcceptedFormat()"
                        @change="changeLogo($event, 'bannerBackGrndImageFile', 'others')"
                        plain
                      ></b-form-file>
                    </label>
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="
                        cityDetails.bannerOptionSelected == 'image' &&
                        !cityDetails.bannerBackGrndImageFile &&
                        !cityDetails.bannerBackGrndImageURI &&
                        formSubmitted
                      "
                      >Banner: Background screen required.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-form-row></template
            >
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="6" md="5" lg="3">
            <h5 class="page-sub-header pt-3">Top header color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="top-header-color"
                  type="text"
                  placeholder="Top header color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.topHeaderColor }"
                  v-model="cityDetails.topHeaderColor"
                  @focus="showTopHeaderColorPicker = true"
                  @blur="showTopHeaderColorPicker = false"
                  @input="applyTopHeaderColorToPicker"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showTopHeaderColorPicker"
                  theme="dark"
                  :color="cityDetails.topHeaderColor"
                  :sucker-hide="true"
                  @input="changeTopHeaderColor"
                  @changeColor="changeTopHeaderColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.topHeaderColor">
                  <div class="preview-city-color" :style="{ backgroundColor: cityDetails.topHeaderColor }"></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.topHeaderColor && formSubmitted">Top header color required.</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="isValidTopHeaderColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" md="5" lg="3" class="offset-lg-1">
            <h5 class="page-sub-header pt-3">Top header text color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="top-header-color"
                  type="text"
                  placeholder="Top header text color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.headerTextColor }"
                  v-model="cityDetails.headerTextColor"
                  @focus="showHeaderTextColorPicker = true"
                  @blur="showHeaderTextColorPicker = false"
                  @input="applyHeaderTextColorPicker"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showHeaderTextColorPicker"
                  theme="dark"
                  :color="cityDetails.headerTextColor"
                  :sucker-hide="true"
                  @input="changeHeaderTextColor"
                  @changeColor="changeHeaderTextColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.headerTextColor">
                  <div class="preview-city-color" :style="{ backgroundColor: cityDetails.headerTextColor }"></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.headerTextColor && formSubmitted"
                >Header text color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidHeaderTextColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row> </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="5" md="5" lg="3">
            <h5 class="page-sub-header pt-3">Notification icon color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="Notification-icon-color"
                  type="text"
                  placeholder="Notification icon color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.notifyIconColor }"
                  v-model="cityDetails.notifyIconColor"
                  @focus="showNotifyIconColor = true"
                  @blur="showNotifyIconColor = false"
                  @input="applyNotifyIconColor"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showNotifyIconColor"
                  theme="dark"
                  :color="cityDetails.notifyIconColor"
                  :sucker-hide="true"
                  @input="changeNotifyIconColor"
                  @changeColor="changeNotifyIconColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.notifyIconColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.notifyIconColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.notifyIconColor && formSubmitted"
                >Notification icon color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidNotifyIconColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" md="5" lg="3" class="offset-lg-1">
            <h5 class="page-sub-header pt-3">Notification icon background color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="top-header-color"
                  type="text"
                  placeholder="Notification icon background color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.notifyIconBackGrndColor }"
                  v-model="cityDetails.notifyIconBackGrndColor"
                  @focus="showNotifyIconBackGrndColor = true"
                  @blur="showNotifyIconBackGrndColor = false"
                  @input="applyNotifyIconBackGrndColor"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showNotifyIconBackGrndColor"
                  theme="dark"
                  :color="cityDetails.notifyIconBackGrndColor"
                  :sucker-hide="true"
                  @input="changeNotifyIconBackGrndColor"
                  @changeColor="changeNotifyIconBackGrndColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.notifyIconBackGrndColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.notifyIconBackGrndColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.notifyIconBackGrndColor && formSubmitted"
                >Notification icon background color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidNotifyIconBackGrndColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="5" md="5" lg="3">
            <h5 class="page-sub-header pt-3">Profile icon color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="Userprofile-icon-color"
                  type="text"
                  placeholder="Userprofile icon color"
                  class="custom-color-input"
                  :class="{
                    valueExist: cityDetails.usrProfileIconColor,
                  }"
                  v-model="cityDetails.usrProfileIconColor"
                  @focus="showUsrProfileIconColor = true"
                  @blur="showUsrProfileIconColor = false"
                  @input="applyUsrProfileIconColor"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showUsrProfileIconColor"
                  theme="dark"
                  :color="cityDetails.usrProfileIconColor"
                  :sucker-hide="true"
                  @input="changeUsrProfileIconColor"
                  @changeColor="changeUsrProfileIconColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.usrProfileIconColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.usrProfileIconColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.usrProfileIconColor && formSubmitted"
                >Profile icon color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidUsrProfileIconColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" md="5" lg="3" class="offset-lg-1">
            <h5 class="page-sub-header pt-3">Profile icon background color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="top-header-color"
                  type="text"
                  placeholder="Userprofile icon background color"
                  class="custom-color-input"
                  :class="{
                    valueExist: cityDetails.usrProfileIconBackGrndColor,
                  }"
                  v-model="cityDetails.usrProfileIconBackGrndColor"
                  @focus="showUsrProfileIconBackGrndColor = true"
                  @blur="showUsrProfileIconBackGrndColor = false"
                  @input="applyUsrProfileIconBackGrndColor"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showUsrProfileIconBackGrndColor"
                  theme="dark"
                  :color="cityDetails.usrProfileIconBackGrndColor"
                  :sucker-hide="true"
                  @input="changeUsrProfileIconBackGrndColor"
                  @changeColor="changeUsrProfileIconBackGrndColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.usrProfileIconBackGrndColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.usrProfileIconBackGrndColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.usrProfileIconBackGrndColor && formSubmitted"
                >Profile icon background color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidUsrProfileIconBackGrndColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="5" md="5" lg="3">
            <h5 class="page-sub-header pt-3">Switch property button text color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="top-header-color"
                  type="text"
                  placeholder="Switch property text color"
                  class="custom-color-input"
                  :class="{
                    valueExist: cityDetails.switchPropBtnTextColor,
                  }"
                  v-model="cityDetails.switchPropBtnTextColor"
                  @focus="showSwitchPropBtnTextColor = true"
                  @blur="showSwitchPropBtnTextColor = false"
                  @input="applySwitchPropBtnTextColor"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showSwitchPropBtnTextColor"
                  theme="dark"
                  :color="cityDetails.switchPropBtnTextColor"
                  :sucker-hide="true"
                  @input="changeSwitchPropBtnTextColor"
                  @changeColor="changeSwitchPropBtnTextColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.switchPropBtnTextColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.switchPropBtnTextColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.switchPropBtnTextColor && formSubmitted">
                Switch property button text color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidSwitchPropBtnTextColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" md="5" lg="3" class="offset-lg-1">
            <h5 class="page-sub-header pt-3">Switch property button background color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="top-header-color"
                  type="text"
                  placeholder="Switch property btn background color"
                  class="custom-color-input"
                  :class="{
                    valueExist: cityDetails.switchPropBtnBackGrndColor,
                  }"
                  v-model="cityDetails.switchPropBtnBackGrndColor"
                  @focus="showSwitchPropBtnBackGrndColor = true"
                  @blur="showSwitchPropBtnBackGrndColor = false"
                  @input="applySwitchPropBtnBackGrndColor"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showSwitchPropBtnBackGrndColor"
                  theme="dark"
                  :color="cityDetails.switchPropBtnBackGrndColor"
                  :sucker-hide="true"
                  @input="changeSwitchPropBtnBackGrndColor"
                  @changeColor="changeSwitchPropBtnBackGrndColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.switchPropBtnBackGrndColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.switchPropBtnBackGrndColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.switchPropBtnBackGrndColor && formSubmitted">
                Switch property button background color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidSwitchPropBtnBackGrndColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="5" md="5" lg="3">
            <h5 class="page-sub-header pt-3">Onboarding text color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="Onboarding-text-color"
                  type="text"
                  placeholder="Onboarding text color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.onBoardingColor }"
                  v-model="cityDetails.onBoardingColor"
                  @focus="showOnBoardingColorPicker = true"
                  @blur="showOnBoardingColorPicker = false"
                  @input="applyOnBoardingColorToPicker"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showOnBoardingColorPicker"
                  theme="dark"
                  :color="cityDetails.onBoardingColor"
                  :sucker-hide="true"
                  @input="changeOnBoardingColor"
                  @changeColor="changeOnBoardingColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.onBoardingColor">
                  <div class="preview-city-color" :style="{ backgroundColor: cityDetails.onBoardingColor }"></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.onBoardingColor && formSubmitted"
                >Onboarding text color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidOnBoardingColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" md="5" lg="3" class="offset-lg-1">
            <h5 class="page-sub-header pt-3">Onboarding background color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="Onboarding-background-color"
                  type="text"
                  placeholder="Onboarding background color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.onBoardingBackGrndColor }"
                  v-model="cityDetails.onBoardingBackGrndColor"
                  @focus="showOnBoardingBackGrndColorPicker = true"
                  @blur="showOnBoardingBackGrndColorPicker = false"
                  @input="applyOnBoardingBackGrndColorToPicker"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showOnBoardingBackGrndColorPicker"
                  theme="dark"
                  :color="cityDetails.onBoardingBackGrndColor"
                  :sucker-hide="true"
                  @input="changeOnBoardingBackGrndColor"
                  @changeColor="changeOnBoardingBackGrndColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.onBoardingBackGrndColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.onBoardingBackGrndColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.onBoardingBackGrndColor && formSubmitted"
                >Onboarding background color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidOnBoardingBackGrndColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col cols="12" sm="5" md="5" lg="3">
            <h5 class="page-sub-header pt-3">Banner text color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="banner-text-color"
                  type="text"
                  placeholder="Banner text color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.bannerTextColor }"
                  v-model="cityDetails.bannerTextColor"
                  @focus="showBannerTextColorPicker = true"
                  @blur="showBannerTextColorPicker = false"
                  @input="applyBannerTextColorToPicker"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showBannerTextColorPicker"
                  theme="dark"
                  :color="cityDetails.bannerTextColor"
                  :sucker-hide="true"
                  @input="changeBannerTextColor"
                  @changeColor="changeBannerTextColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.bannerTextColor">
                  <div
                    class="preview-city-color"
                    :style="{
                      backgroundColor: cityDetails.bannerTextColor,
                    }"
                  ></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.bannerTextColor && formSubmitted"
                >Banner text color required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback :state="isValidBannerTextColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" md="5" lg="3" class="offset-lg-1">
            <h5 class="page-sub-header pt-3">Button color</h5>
          </b-col>
          <b-col cols="10" sm="7" md="5" lg="2">
            <b-form-group>
              <div>
                <b-form-input
                  id="button-color"
                  type="text"
                  placeholder="Button color"
                  class="custom-color-input"
                  :class="{ valueExist: cityDetails.buttonColor }"
                  v-model="cityDetails.buttonColor"
                  @focus="showButtonColorPicker = true"
                  @blur="showButtonColorPicker = false"
                  @input="applyButtonColorToPicker"
                  required
                ></b-form-input>
                <color-picker
                  v-if="showButtonColorPicker"
                  theme="dark"
                  :color="cityDetails.buttonColor"
                  :sucker-hide="true"
                  @input="changeButtonColor"
                  @changeColor="changeButtonColor"
                  class="color-picker"
                />
                <div class="preview-city-color-wraper" v-if="cityDetails.buttonColor">
                  <div class="preview-city-color" :style="{ backgroundColor: cityDetails.buttonColor }"></div>
                </div>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.buttonColor && formSubmitted">Button color required.</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="isValidButtonColor">Invalid color <code></code></b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <b-form-group>
              <h4 class="page-sub-header">Onboarding welcome title</h4>
              <h6 class="font-14">
                <InfoCircelYellow class="mr-1 mt-n1" />
                <strong>Important Tip:</strong> Set the title as Heading2 to make it accessible.
              </h6>
              <editor :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="cityDetails.onBoardingTitle" />
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.onBoardingTitle && formSubmitted"
                >Onboarding welcome title required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <b-form-group>
              <h4 class="page-sub-header">Onboarding text</h4>
              <h6 class="font-14">
                <InfoCircelYellow class="mr-1 mt-n1" />
                <strong>Important Tip:</strong> Set headings in this text to Heading3 to make it accessible.
              </h6>
              <editor :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="cityDetails.onBoardingTxt" />
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.onBoardingTxt && formSubmitted">Onboarding text required.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <b-form-group>
              <h4 class="page-sub-header">Footer text</h4>
              <editor :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="cityDetails.footerTxt" />
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.footerTxt && formSubmitted">Footer text required.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <h4 class="page-sub-header">Terms and condition</h4>
            <b-form-group>
              <b-form-radio-group v-model="cityDetails.termsConditionSelected" :options="linkContentOptions" name="radio-termsCondition"></b-form-radio-group>
            </b-form-group>
            <b-form-group v-if="cityDetails.termsConditionSelected == 'link'">
              <div class="floating-input-field">
                <b-form-input id="terms-condition-link" type="text" placeholder=" " v-model="cityDetails.termsConditionLnk" required></b-form-input>
                <label for="terms-condition-link">Terms and condition link</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.termsConditionLnk && formSubmitted"
                  >Terms and condition link required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.termsConditionLnk && formSubmitted && !validateUrl(cityDetails.termsConditionLnk)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
            <b-form-group v-else>
              <editor :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="cityDetails.termsConditionContent" />
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.termsConditionContent && formSubmitted"
                >Terms and condition content required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <h4 class="page-sub-header">Privacy policy</h4>
            <b-form-group>
              <b-form-radio-group v-model="cityDetails.privacyPolicySelected" :options="linkContentOptions" name="radio-privacy-policy"></b-form-radio-group>
            </b-form-group>
            <b-form-group v-if="cityDetails.privacyPolicySelected == 'link'">
              <div class="floating-input-field">
                <b-form-input id="privacy-policy-link" type="text" placeholder=" " v-model="cityDetails.privacyPolicyLnk" required></b-form-input>
                <label for="privacy-policy-link">Privacy policy link</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.privacyPolicyLnk && formSubmitted"
                  >Privacy policy link required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.privacyPolicyLnk && formSubmitted && !validateUrl(cityDetails.privacyPolicyLnk)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
            <b-form-group v-else>
              <editor :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="cityDetails.privacyPolicyContent" />
              <b-form-invalid-feedback class="d-block" v-if="!cityDetails.privacyPolicyContent && formSubmitted"
                >Privacy policy content required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="5" class="mt-3">
            <h4 class="page-sub-header">Back link text</h4>
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input id="back-link-text" type="text" placeholder=" " v-model="cityDetails.siteBackText" required></b-form-input>
                <label for="back-link-text">Back link text</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.siteBackText && cityDetails.siteBackLink && formSubmitted"
                  >Back link text required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" class="mt-3">
            <h4 class="page-sub-header">Back link</h4>
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input id="back-link" type="url" placeholder=" " v-model="cityDetails.siteBackLink" required></b-form-input>
                <label for="back-link">Back link</label>
                <b-form-invalid-feedback class="d-block" v-if="!cityDetails.siteBackLink && cityDetails.siteBackText && formSubmitted"
                  >Back link required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.siteBackLink && formSubmitted && !validateUrl(cityDetails.siteBackLink)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <b-form-group>
              <h4 class="page-sub-header">Social media connect link</h4>
              <b-row v-for="(option, index) in socialMediaConnectOptions" :key="index" class="mb-3 align-items-center">
                <b-col cols="12" sm="11" md="6" lg="2">
                  <b-form-checkbox
                    v-model="option.isSelected"
                    @change="option.url = option.isSelected ? option.url : ''"
                    :key="option.id"
                    name="social-media-connect"
                  >
                    {{ option.socialMediaName }}
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="11" md="8" lg="6">
                  <div class="floating-input-field">
                    <b-form-input
                      :key="option.id"
                      :id="'social-media-connect-url' + index"
                      type="text"
                      placeholder=" "
                      v-model="option.url"
                      :disabled="!option.isSelected"
                      required
                    ></b-form-input>
                    <label :for="'social-media-connect-url' + index">URL</label>
                    <b-form-invalid-feedback class="d-block" v-if="formSubmitted && option.url && option.isSelected && !validateUrl(option.url)"
                      >Invalid url.</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback class="d-block" v-if="formSubmitted && !option.url && option.isSelected"
                      >URL is required .</b-form-invalid-feedback
                    >
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="5" class="mt-3">
            <b-form-group>
              <h4 class="page-sub-header">Help url</h4>
              <div class="floating-input-field">
                <b-form-input id="city-url" type="text" placeholder=" " v-model="cityDetails.helpUrl" required></b-form-input>
                <label for="city-url">Help url</label>
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.helpUrl && formSubmitted && !validateUrl(cityDetails.helpUrl)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="5" class="mt-3">
            <b-form-group>
              <h4 class="page-sub-header">Contact us url</h4>
              <div class="floating-input-field">
                <b-form-input id="contact-url" type="text" placeholder=" " v-model="cityDetails.contactUsUrl" required></b-form-input>
                <label for="contact-url">Contact us url</label>
                <b-form-invalid-feedback class="d-block" v-if="cityDetails.contactUsUrl && formSubmitted && !validateUrl(cityDetails.contactUsUrl)"
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="my-4">
          <b-col>
            <b-button type="submit" v-activeBlur variant="primary" :disabled="saveLoadingIcon"
              >Save <b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
            <b-button type="button" v-activeBlur @click="onResetCity" class="ml-3" variant="outline-secondary">Cancel</b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import colorPicker from '@caohenghu/vue-colorpicker'
import { TinyEditorOptions, DISPLAY_MESSAGES } from '../../utilities/constants'
import { cloneDeep } from 'lodash'
import { useValidateFields } from '@/composables/useValidateFields'
import InfoCircelYellow from '../../assets/svg/info-circle-yellow.svg'
export default {
  name: 'GeneralCity',
  props: ['cityDetails', 'isEditPage', 'isSettingsPage'],
  setup() {
    const { validateUrl, validateHostname, getAcceptedFormat, fileTypeExist, fileSizeLimit, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, validateHostname, getAcceptedFormat, fileTypeExist, fileSizeLimit, scrollToErrorMessage }
  },
  components: {
    editor: Editor,
    colorPicker,
    InfoCircelYellow,
  },
  data() {
    return {
      selected: '',
      tabIndex: 0,
      showForm: true,
      formSubmitted: false,
      saveLoadingIcon: false,
      showTopHeaderColorPicker: false,
      showHeaderTextColorPicker: false,
      showNotifyIconBackGrndColor: false,
      showNotifyIconColor: false,
      showUsrProfileIconBackGrndColor: false,
      showUsrProfileIconColor: false,
      showSwitchPropBtnBackGrndColor: false,
      showSwitchPropBtnTextColor: false,
      showButtonColorPicker: false,
      showBannerBackGrndColorPicker: false,
      showBannerTextColorPicker: false,
      showOnBoardingBackGrndColorPicker: false,
      showOnBoardingColorPicker: false,
      tinyEditorOptions: TinyEditorOptions,
      bannerOptions: [
        { text: 'Color', value: 'color' },
        { text: 'Image', value: 'image' },
      ],
      linkContentOptions: [
        { text: 'Link', value: 'link' },
        { text: 'Content', value: 'content' },
      ],
      socialMediaConnectOptions: [],
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      tenantServiceIdList: [],
      timezoneList: [],
    }
  },
  computed: {
    getSVGImg() {
      return (file) => {
        return `data:image/svg+xml;utf8,${file}`
      }
    },
    getObjectURL() {
      return (file, uri) => {
        return file ? URL.createObjectURL(file) : uri
      }
    },
    isValidTopHeaderColor() {
      if (this.cityDetails.topHeaderColor) {
        return this.cityDetails.topHeaderColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidHeaderTextColor() {
      if (this.cityDetails.headerTextColor) {
        return this.cityDetails.headerTextColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidNotifyIconBackGrndColor() {
      if (this.cityDetails.notifyIconBackGrndColor) {
        return this.cityDetails.notifyIconBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidNotifyIconColor() {
      if (this.cityDetails.notifyIconColor) {
        return this.cityDetails.notifyIconColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidUsrProfileIconBackGrndColor() {
      if (this.cityDetails.usrProfileIconBackGrndColor) {
        return this.cityDetails.usrProfileIconBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidUsrProfileIconColor() {
      if (this.cityDetails.usrProfileIconColor) {
        return this.cityDetails.usrProfileIconColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidSwitchPropBtnBackGrndColor() {
      if (this.cityDetails.switchPropBtnBackGrndColor) {
        return this.cityDetails.switchPropBtnBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidSwitchPropBtnTextColor() {
      if (this.cityDetails.switchPropBtnTextColor) {
        return this.cityDetails.switchPropBtnTextColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidButtonColor() {
      if (this.cityDetails.buttonColor) {
        return this.cityDetails.buttonColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidBannerBackGrndColor() {
      if (this.cityDetails.bannerBackGrndColor) {
        return this.cityDetails.bannerBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidBannerTextColor() {
      if (this.cityDetails.bannerTextColor) {
        return this.cityDetails.bannerTextColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidOnBoardingBackGrndColor() {
      if (this.cityDetails.nBoardingBackGrnd) {
        return this.cityDetails.nBoardingBackGrnd.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    isValidOnBoardingColor() {
      if (this.cityDetails.onBoardingColor) {
        return this.cityDetails.onBoardingColor.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      socialMediaConnectNames: (state) => state.cities.socialMediaConnectNames,
    }),
  },
  mounted() {
    this.getSocialMediaConnectNames()
    this.getTenantServiceIdList()
    this.getTimezoneList()
    if (!this.socialMediaConnectNames.length) {
      this.$store.dispatch('cities/getSocialMediaConnectNames').then((res) => {
        this.getSocialMediaConnectNames()
      })
    }
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },

    getSocialMediaConnectNames() {
      if (this.socialMediaConnectNames && this.cityDetails && this.isEditPage) {
        const tempSocialMediaConnect = cloneDeep(this.socialMediaConnectNames)
        const editSocilaMediaConnect = this.cityDetails.socialMediaConnectLst
        tempSocialMediaConnect.forEach((data) => {
          const index = editSocilaMediaConnect.findIndex((x) => x.id === data.id)
          data.isSelected = index > -1 ? editSocilaMediaConnect[index].isSelected : data.isSelected
          data.url = index > -1 ? editSocilaMediaConnect[index].url : data.url
        })
        this.socialMediaConnectOptions = tempSocialMediaConnect
      } else {
        this.socialMediaConnectOptions = cloneDeep(this.socialMediaConnectNames)
      }
    },
    getTenantServiceIdList() {
      this.tenantServiceIdList = []
      this.$store
        .dispatch('cities/getTenantServiceIdList')
        .then((res) => {
          if (res && res.data && res.data.data) {
            this.tenantServiceIdList = res.data.data
          }
        })
        .catch(() => {
          this.tenantServiceIdList = []
        })
    },
    getTimezoneList() {
      this.timezoneList = []
      this.$store
        .dispatch('cities/getTimezones')
        .then((res) => {
          if (res && res.data) {
            this.timezoneList = res.data
          }
        })
        .catch(() => {
          this.timezoneList = []
        })
    },
    validateCityForm() {
      const cityName = !!this.cityDetails.cityName
      const tenantServiceId = !!this.cityDetails.tenantServiceId
      const appName = !!this.cityDetails.appName
      const hostName = this.cityDetails.hostName ? this.validateHostname(this.cityDetails.hostName) : false
      const timezone = !!this.cityDetails.timeZone
      const favIconLink = this.cityDetails.favIconLnk ? this.validateUrl(this.cityDetails.favIconLnk) : false
      const shortcutIconLnk = this.cityDetails.shortcutIconLnk ? this.validateUrl(this.cityDetails.shortcutIconLnk) : true
      const logoBanner = this.cityDetails.logoBannerFile ? true : !!this.cityDetails.logoBannerURI
      const logoBannerDark = this.cityDetails.darkLogoBannerFile ? true : !!this.cityDetails.darkLogoBannerURI
      const footerLogo = this.cityDetails.footerLogoFile ? true : !!this.cityDetails.footerLogoURI
      const footerLogoDark = this.cityDetails.darkFooterLogoFile ? true : !!this.cityDetails.darkFooterLogoURI
      const backgroundOnBoarding = this.cityDetails.backgroundOnBoardingFile ? true : !!this.cityDetails.backgroundOnBoardingURI
      const loginBackGrndScreen = this.cityDetails.loginBackGrndScreenFile ? true : !!this.cityDetails.loginBackGrndScreenURI
      const bannerOption =
        this.cityDetails.bannerOptionSelected === 'color'
          ? this.cityDetails.bannerBackGrndColor
            ? this.cityDetails.bannerBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
            : false
          : this.cityDetails.bannerBackGrndImageFile
          ? true
          : !!this.cityDetails.bannerBackGrndImageURI
      if (this.cityDetails.bannerOptionSelected === 'color') {
        this.cityDetails.bannerBackGrndImageFile = null
        this.cityDetails.bannerBackGrndImageURI = null
      } else {
        this.cityDetails.bannerBackGrndColor = null
      }
      const topHeaderColor = this.cityDetails.topHeaderColor ? this.cityDetails.topHeaderColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const headerTextColor = this.cityDetails.headerTextColor ? this.cityDetails.headerTextColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const notifyIconColor = this.cityDetails.notifyIconColor ? this.cityDetails.notifyIconColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const notifyIconBackGrndColor = this.cityDetails.notifyIconBackGrndColor
        ? this.cityDetails.notifyIconBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
        : false
      const usrProfileIconColor = this.cityDetails.usrProfileIconColor ? this.cityDetails.usrProfileIconColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const usrProfileIconBackGrndColor = this.cityDetails.usrProfileIconBackGrndColor
        ? this.cityDetails.usrProfileIconBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
        : false
      const switchPropBtnBackGrndColor = this.cityDetails.switchPropBtnBackGrndColor
        ? this.cityDetails.switchPropBtnBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
        : false
      const switchPropBtnTextColor = this.cityDetails.switchPropBtnTextColor ? this.cityDetails.switchPropBtnTextColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const buttonColor = this.cityDetails.buttonColor ? this.cityDetails.buttonColor.match(/^#[a-f0-9]{6}$/i) !== null : false

      const bannerTextColor = this.cityDetails.bannerTextColor ? this.cityDetails.bannerTextColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const onBoardingBackGrndColor = this.cityDetails.onBoardingBackGrndColor
        ? this.cityDetails.onBoardingBackGrndColor.match(/^#[a-f0-9]{6}$/i) !== null
        : false
      const onBoardingColor = this.cityDetails.onBoardingColor ? this.cityDetails.onBoardingColor.match(/^#[a-f0-9]{6}$/i) !== null : false
      const onBoardingTitle = !!this.cityDetails.onBoardingTitle
      const onBoardingTxt = !!this.cityDetails.onBoardingTxt
      const footerTxt = !!this.cityDetails.footerTxt
      const termsCondition =
        this.cityDetails.termsConditionSelected === 'link' ? !!this.cityDetails.termsConditionLnk : !!this.cityDetails.termsConditionContent

      this.cityDetails.termsConditionSelected === 'link' ? (this.cityDetails.termsConditionContent = null) : (this.cityDetails.termsConditionLnk = null)

      const termsLinkValidation = this.cityDetails.termsConditionLnk ? this.validateUrl(this.cityDetails.termsConditionLnk) : true
      const privacyCondition = this.cityDetails.privacyPolicySelected === 'link' ? !!this.cityDetails.privacyPolicyLnk : !!this.cityDetails.privacyPolicyContent
      this.cityDetails.privacyPolicySelected === 'link' ? (this.cityDetails.privacyPolicyContent = null) : (this.cityDetails.privacyPolicyLnk = null)
      const privacyLinkValidation = this.cityDetails.privacyPolicyLnk ? this.validateUrl(this.cityDetails.privacyPolicyLnk) : true
      let backLink = true
      if (this.cityDetails.siteBackLink || this.cityDetails.siteBackText) {
        const siteBackText = !!this.cityDetails.siteBackText
        const siteBackLink = this.cityDetails.siteBackLink ? this.validateUrl(this.cityDetails.siteBackLink) : false
        backLink = siteBackText && siteBackLink
      }
      const helpUrl = this.cityDetails.helpUrl ? this.validateUrl(this.cityDetails.helpUrl) : true
      const contactUsUrl = this.cityDetails.contactUsUrl ? this.validateUrl(this.cityDetails.contactUsUrl) : true
      const socialMediaConnectOptionsUrl = !this.socialMediaConnectOptions.some((socialMedia) => socialMedia.isSelected && !this.validateUrl(socialMedia.url))
      this.scrollToErrorMessage()
      return (
        cityName &&
        tenantServiceId &&
        appName &&
        hostName &&
        timezone &&
        favIconLink &&
        shortcutIconLnk &&
        logoBanner &&
        logoBannerDark &&
        footerLogo &&
        footerLogoDark &&
        backgroundOnBoarding &&
        loginBackGrndScreen &&
        topHeaderColor &&
        headerTextColor &&
        notifyIconColor &&
        notifyIconBackGrndColor &&
        usrProfileIconColor &&
        usrProfileIconBackGrndColor &&
        switchPropBtnBackGrndColor &&
        switchPropBtnTextColor &&
        buttonColor &&
        bannerOption &&
        bannerTextColor &&
        onBoardingBackGrndColor &&
        onBoardingColor &&
        onBoardingTitle &&
        onBoardingTxt &&
        footerTxt &&
        termsCondition &&
        termsLinkValidation &&
        privacyCondition &&
        privacyLinkValidation &&
        backLink &&
        helpUrl &&
        contactUsUrl &&
        socialMediaConnectOptionsUrl
      )
    },
    onSubmitCity() {
      this.formSubmitted = true
      if (this.validateCityForm()) {
        this.cityDetails.socialMediaConnect = cloneDeep(JSON.stringify(this.socialMediaConnectOptions))
        this.cityDetails.socialMediaConnectLst = cloneDeep(this.socialMediaConnectOptions)
        this.saveLoadingIcon = true
        const action = 'cities/updateTenantSettings'
        this.cityDetails.tenantID = this.isEditPage ? this.cityDetails.tenantID : 0
        this.$store
          .dispatch(action, this.cityDetails)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data && response.data.isSuccess) {
              this.showToaster(false, this.isEditPage ? 'UPDATE_CITY' : 'ADD_CITY', 'success')
              if (!this.isSettingsPage) {
                if (this.isEditPage) {
                  this.$router.push('/cities').catch(() => {})
                } else {
                  this.cityDetails.tenantID = response.data.tenantID
                  this.$router.replace(`/cities/edit-city/${response.data.tenantID}/widget-setup`)
                  this.$emit('updateIsEditPage', true)
                }
              }
              this.$store.dispatch('common/getAdminTenants')
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    },
    onResetCity() {
      this.$emit('resetCityDetails')
      this.showForm = false
      this.$nextTick(() => {
        this.showForm = true
      })
    },
    changeTopHeaderColor(color) {
      this.cityDetails.topHeaderColor = color.hex
    },
    changeHeaderTextColor(color) {
      this.cityDetails.headerTextColor = color.hex
    },
    changeNotifyIconBackGrndColor(color) {
      this.cityDetails.notifyIconBackGrndColor = color.hex
    },
    changeNotifyIconColor(color) {
      this.cityDetails.notifyIconColor = color.hex
    },
    changeUsrProfileIconBackGrndColor(color) {
      this.cityDetails.usrProfileIconBackGrndColor = color.hex
    },
    changeUsrProfileIconColor(color) {
      this.cityDetails.usrProfileIconColor = color.hex
    },
    changeSwitchPropBtnBackGrndColor(color) {
      this.cityDetails.switchPropBtnBackGrndColor = color.hex
    },
    changeSwitchPropBtnTextColor(color) {
      this.cityDetails.switchPropBtnTextColor = color.hex
    },
    changeButtonColor(color) {
      this.cityDetails.buttonColor = color.hex
    },
    changeBannerBackGrndColor(color) {
      this.cityDetails.bannerBackGrndColor = color.hex
    },
    changeBannerTextColor(color) {
      this.cityDetails.bannerTextColor = color.hex
    },
    changeOnBoardingBackGrndColor(color) {
      this.cityDetails.onBoardingBackGrndColor = color.hex
    },
    changeOnBoardingColor(color) {
      this.cityDetails.onBoardingColor = color.hex
    },
    applyTopHeaderColorToPicker() {
      this.showTopHeaderColorPicker = false
      this.$nextTick(() => {
        this.showTopHeaderColorPicker = true
      })
    },
    applyHeaderTextColorPicker() {
      this.showHeaderTextColorPicker = false
      this.$nextTick(() => {
        this.showHeaderTextColorPicker = true
      })
    },
    applyNotifyIconBackGrndColor() {
      this.showNotifyIconBackGrndColor = false
      this.$nextTick(() => {
        this.showNotifyIconBackGrndColor = true
      })
    },
    applyNotifyIconColor() {
      this.showNotifyIconColor = false
      this.$nextTick(() => {
        this.showNotifyIconColor = true
      })
    },
    applyUsrProfileIconBackGrndColor() {
      this.showUsrProfileIconBackGrndColor = false
      this.$nextTick(() => {
        this.showUsrProfileIconBackGrndColor = true
      })
    },
    applyUsrProfileIconColor() {
      this.showUsrProfileIconColor = false
      this.$nextTick(() => {
        this.showUsrProfileIconColor = true
      })
    },
    applySwitchPropBtnBackGrndColor() {
      this.showSwitchPropBtnBackGrndColor = false
      this.$nextTick(() => {
        this.showSwitchPropBtnBackGrndColor = true
      })
    },
    applySwitchPropBtnTextColor() {
      this.showSwitchPropBtnTextColor = false
      this.$nextTick(() => {
        this.showSwitchPropBtnTextColor = true
      })
    },
    applyButtonColorToPicker() {
      this.showButtonColorPicker = false
      this.$nextTick(() => {
        this.showButtonColorPicker = true
      })
    },
    applyBannerBackGrndColorToPicker() {
      this.showBannerBackGrndColorPicker = false
      this.$nextTick(() => {
        this.showBannerBackGrndColorPicker = true
      })
    },
    applyBannerTextColorToPicker() {
      this.showBannerTextColorPicker = false
      this.$nextTick(() => {
        this.showBannerTextColorPicker = true
      })
    },
    applyOnBoardingBackGrndColorToPicker() {
      this.showOnBoardingBackGrndColorPicker = false
      this.$nextTick(() => {
        this.showOnBoardingBackGrndColorPicker = true
      })
    },
    applyOnBoardingColorToPicker() {
      this.showOnBoardingColorPicker = false
      this.$nextTick(() => {
        this.showOnBoardingColorPicker = true
      })
    },
    showFileAlertModal(message) {
      this.$bvModal.msgBoxOk(message, {
        title: 'Warning',
        size: 'md',
        buttonSize: 'lg',
        okVariant: 'danger',
        bodyClass: 'text-center',
        headerClass: 'p5-5 theme-font-bold justify-content-center border-bottom-0',
        footerClass: 'justify-content-center border-top-0',
        centered: true,
      })
    },
    changeLogo(ev, type, supportType) {
      if (ev.target.files && ev.target.files.length) {
        const fileNameDetails = ev.target.files[0]
        const fileName = fileNameDetails.name
        const idxDot = fileName.lastIndexOf('.') + 1
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
        if (this.fileTypeExist(extFile, supportType)) {
          if (this.fileSizeLimit(fileNameDetails.size)) {
            if (type === 'bannerBackGrndImageFile') {
              const img = new Image()
              img.onload = () => {
                if (img.width === 1800 && img.height === 140) {
                  this.cityDetails[type] = fileNameDetails
                } else {
                  ev.target.value = ''
                  this.showFileAlertModal('The uploaded image dimension should be 1800 x 140.')
                }
              }
              img.src = URL.createObjectURL(fileNameDetails)
            } else if (type === 'backgroundOnBoardingFile') {
              const img = new Image()
              img.onload = () => {
                if (img.width <= 740 && img.height === 240) {
                  this.cityDetails[type] = fileNameDetails
                } else {
                  ev.target.value = ''
                  this.showFileAlertModal('Image dimension can be any width between 0px to 740px but the height should be 240px.')
                }
              }
              img.src = URL.createObjectURL(fileNameDetails)
            } else {
              this.cityDetails[type] = fileNameDetails
            }
          } else {
            ev.target.value = ''
            this.showFileAlertModal(`Unable to update. The ${fileName} file exceeds the image file size upload limit.`)
            // ev.target.value = "";
            // this.cityDetails[type] = null;
          }
        } else {
          ev.target.value = ''
          this.showFileAlertModal(`Unable to update. ${fileName} is an invalid image type.`)
          // ev.target.value = "";
          // this.cityDetails[type] = null;
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
